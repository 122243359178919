//
//
//
//

export default {
  data: () => ({ prefersReducedMotion: false }),
  computed: {
    scrollText() {
      if (this.prefersReducedMotion) return 'Scroll Down'
      else return 'Scroll'
    },
  },
  mounted() {},
}
